<template>
  <c-flex :flexDirection="['column', 'column', 'row']" class="pt-10">
    <c-flex h="100%" :flex="hideSidebar ? '1' : '3'">
      <c-flex w="100%" h="500px" v-if="event === null" justify="center" align="center">
        <c-spinner size="xl" color="orange.500" thickness="2px"></c-spinner>
      </c-flex>
      <c-box w="100%" v-else>
        <c-box borderRadius="10px" px="5" pt="5" pb="5" bg="#4267B222">
          <c-box
            style="position: relative"
            h="280px"
            w="100%"
            rounded="lg"
            objectFit="cover"
            alt="back"
            v-chakra="{
              backgroundImage: `url('${
                event.thumb_nail
                  ? event.thumb_nail
                  : 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRRFo4PR9dPR7LmV1CqeEB6F5Z3jikCg8xBBA&usqp=CAU'
              }')`,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundSize: 'cover',
            }"
          >
          </c-box>
          <c-flex mt="2" w="100%" align="center" justify="space-between">
            <c-flex v-if="isPastEvent" align="center" mt="2">
              <c-icon name="info" />
              <c-text ml="2" fontSize="13px">Event ended</c-text>
            </c-flex>
            <c-icon-button
                v-if="isCreatorOfEvent"
                variant-color="orange"
                @click="openEditDialog"
                aria-label="edit"
                size="xs"
                icon="pen"
            />
          </c-flex>
          <c-text mt="3" fontSize="20px" fontWeight="600">{{
            event.name
          }}</c-text>
          <c-flex flexDirection="row" mt="2">
            <c-text fontSize="14px" fontWeight="400">Event By</c-text>
            <c-text ml="1" fontSize="14px" fontWeight="700"
              >{{event.posts[0]?.company.name}}</c-text
            >
          </c-flex>
          <c-flex align="center" mt="3">
            <c-icon size="14px" name="calendar" />
            <c-text ml="2" fontSize="14px" fontWeight="400"
              >{{
                simplifyDate(
                  new Date(event.start_date + ',' + event.start_time)
                )
              }}
              -
              {{
                simplifyDate(new Date(event.end_date + ',' + event.end_time))
              }}</c-text
            >
          </c-flex>
          <c-flex>
            <c-flex mt="2" justify="start" align="center">
              <c-icon
                v-if="event.type === 'VIRTUAL'"
                size="14px"
                name="video"
              />
              <c-icon v-else size="14px" name="users" />
              <c-flex h="20px" align="center">
                <c-text ml="2" fontSize="14px" fontWeight="600">{{
                  event.type === 'VIRTUAL' ? 'Online' : 'In person'
                }}</c-text>
                <a
                  v-if="event.type === 'VIRTUAL' && event.meeting_link !== null"
                  style="margin-left: 80px"
                  @click="isPastEvent ? $event.preventDefault: null"
                  :href="isPastEvent ? null : event.meeting_link"
                >
                  <c-text
                    :class="{link_disabled: isPastEvent, link_active: !isPastEvent}"
                    fontWeight="500"
                    fontSize="14px"
                    >Join Meeting Here</c-text
                  >
                </a>
              </c-flex>
            </c-flex>
          </c-flex>
          <c-flex
            @click="isAttendeesModalOpen = true"
            align="center"
            mt="3"
            ml="2"
          >
            <c-avatar
              :key="attender.id"
              v-for="attender in truncatedAttendees"
              ml="-7px"
              size="sm"
              :name="
                attender.attendee.firstname + ' ' + attender.attendee.lastname
              "
              :src="attender.attendee.profilePhoto"
            />
            <c-text ml="2" fontSize="14px" fontWeight="600"
              >{{ attendees.length }}
              {{ attendees.length === 1 ? 'attendant' : 'attendants' }}</c-text
            >
          </c-flex>
          <c-button
            v-if="attendingEvent"
            @click="leave"
            :disabled="isPastEvent"
            h="35px"
            md="sm"
            mt="3"
            variant-color="red"
            >Leave</c-button
          >
          <c-button
            v-else
            @click="attend"
            :disabled="isPastEvent"
            h="35px"
            md="sm"
            mt="3"
            variant-color="vc-orange"
            >Attend</c-button
          >
          <c-flex
            v-if="event.posts.length"
            mt="5"
            borderRadius="8px"
            bg="vc-gray.20"
            py="2"
            px="4"
            mb="2"
          >
            <c-pseudo-box
              :_hover="{
                textDecoration: 'none',
                cursor: 'pointer',
              }"
              display="flex"
              @click="togglePostLike"
              mr="12"
              alignItems="center"
            >
              <svg
                v-chakra="{
                  fill: 'gray.400',
                  w: '4',
                  h: '4',
                  mr: '2',
                }"
                v-if="!isLikedPost"
              >
                <use href="@/assets/icons/icon-heart.svg#heart"></use>
              </svg>
              <svg
                v-else
                v-chakra="{
                  w: '4',
                  h: '4',
                  mr: '2',
                }"
              >
                <use href="@/assets/icons/icon-heart-red.svg#heart"></use>
              </svg>
              <c-text
                :color="isLikedPost ? 'red.300' : 'gray.400'"
                fontSize="sm"
              >
                {{ postLikes.length }}
              </c-text>
            </c-pseudo-box>
            <c-pseudo-box
              :_hover="{
                textDecoration: 'none',
                cursor: 'pointer',
              }"
              display="flex"
              mr="12"
              alignItems="center"
              @click="isCommentBoxVisible = !isCommentBoxVisible"
            >
              <svg
                v-chakra="{
                  fill: 'gray.400',
                  w: '4',
                  h: '4',
                  mr: '2',
                }"
              >
                <use href="@/assets/icons/icon-chat.svg#chat"></use>
              </svg>
              <c-text v-if="event.posts.length" color="gray.400" fontSize="sm">
                {{ event.posts[0].post_comments.length }}
              </c-text>
            </c-pseudo-box>
            <c-pseudo-box
              :_hover="{
                textDecoration: 'none',
                cursor: 'pointer',
              }"
              display="flex"
              mr="4"
              alignItems="center"
              @click="onShareClick(event)"
            >
              <svg v-chakra fill="gray.400" w="4" h="4" ml="1">
                <use href="@/assets/icons/share-box-fill.svg#share"></use>
              </svg>
            </c-pseudo-box>
          </c-flex>
          <transition name="fade">
            <c-flex
              px="6"
              py="3"
              align="center"
              minHeight="80px"
              borderTopWidth="1px"
              mt="3"
              mx="-1.5em"
              v-show="isCommentBoxVisible || isEditMode"
            >
              <c-avatar
                :name="user.firstname + ' ' + user.lastname"
                :src="user.profilePhoto"
                mr="3"
              />
              <c-flex
                align="center"
                flexBasis="calc(100% - 65px)"
                flexGrow="0"
                borderWidth="1px"
                borderColor="red"
                borderRadius="4px"
                maxWidth="calc(100% - 65px)"
              >
                <c-box
                  flexBasis="calc(100% - 40px)"
                  flexGrow="0"
                  minHeight="40px"
                  maxWidth="calc(100% - 40px)"
                >
                  <quill-editor
                    class="editor"
                    ref="textEditor"
                    v-model="newComment"
                    @change="onChange"
                    :options="editorOptions"
                  />
                </c-box>
                <c-button
                  px="0"
                  border="none"
                  :disabled="!newComment || !newComment.trim()"
                  display="flex"
                  justifyContent="center"
                  variant="outline"
                  @click="submitComment"
                >
                  <c-spinner
                    v-if="isSavingComment"
                    color="blue.400"
                    thickness="2px"
                  ></c-spinner>
                  <svg
                    v-else
                    v-chakra="{
                      fill: 'gray.400',
                      w: '16px',
                      h: '16px',
                    }"
                  >
                    <use href="@/assets/icons/icon-send-button.svg#send"></use>
                  </svg>
                </c-button>
              </c-flex>
            </c-flex>
          </transition>
          <c-box px="6" py="5" v-if="postComments.length && event.posts.length" bg="gray.100">
            <c-stack :spacing="4">
              <template v-for="(comment, index) in postComments">
                <c-box :key="comment.id">
                  <Comment
                    :comment="comment"
                    v-show="isAllCommentsShown || index == 0"
                    @editComment="onEditComment(comment)"
                    @deleteComment="onDeleteComment(comment)"
                  />
                </c-box>
              </template>
            </c-stack>
            <c-box mt="2" v-if="postComments.length > 1">
              <c-link
                @click="isAllCommentsShown = !isAllCommentsShown"
                fontWeight="600"
                fontSize="sm"
              >
                Show {{ isAllCommentsShown ? 'less' : 'more comments' }}
              </c-link>
            </c-box>
          </c-box>
          <c-alert-dialog
            :is-open="isDeleteDialogOpen"
            :least-destructive-ref="$refs.cancelRef"
            :on-close="closeDeleteDialog"
          >
            <c-alert-dialog-overlay />
            <c-alert-dialog-content>
              <c-alert-dialog-header font-size="lg" font-weight="bold">
                Delete Comment
              </c-alert-dialog-header>
              <c-alert-dialog-body>
                Are you sure? You can't undo this action afterwards.
              </c-alert-dialog-body>
              <c-alert-dialog-footer>
                <c-button ref="cancelRef" @click="closeDeleteDialog">
                  Cancel
                </c-button>
                <c-button variantColor="red" @click="deleteComment" ml="3">
                  Delete
                  <c-spinner
                    ml="3"
                    v-if="isDeletingComment"
                    color="#fff"
                    thickness="2px"
                  />
                </c-button>
              </c-alert-dialog-footer>
            </c-alert-dialog-content>
          </c-alert-dialog>
          <share-content
            :isShareOpen="isShareOpen"
            @close="onShareClick"
            :url="`events/${event.id}`"
            shareTitle="Share Post"
            :postTitle="'Post on VibrantCreator'"
            :postDescription="'Hey! I just shared a new post on VibrantCreator, check it out'"
          />
        </c-box>

        <c-box mt="5" borderRadius="10px" px="5" pt="5" pb="5" bg="#4267B222">
          <c-text mb="3" fontSize="16px" fontWeight="700">About</c-text>
          <c-text fontSize="15px" lineHeight="24px">
            {{ event.description }}
          </c-text>
        </c-box>

        <c-box v-if="event.event_speakers.length" mt="5" borderRadius="10px" px="5" pt="5" pb="5" bg="#4267B222">
          <c-text mb="3" fontSize="16px" fontWeight="700">Speakers</c-text>
          <c-box class="grid-container">
            <div :key="speaker.id" v-for="speaker in event.event_speakers">
              <speaker-card :speaker="speaker" />
            </div>
          </c-box>
        </c-box>
      </c-box>
    </c-flex>
    <c-flex v-if="!hideSidebar" ml="5" :flex="hideSidebar ? '0' : '3'">
      <sidebar :eventId="Number(eventId)" />
    </c-flex>
    <c-modal
      :is-open="isAttendeesModalOpen"
      :on-close="closeAttendeesModal"
      size="xl"
      :is-centered="true"
    >
      <c-modal-content>
        <c-modal-header>People Attending</c-modal-header>
        <c-modal-close-button />
        <c-box class="modal-content">
          <c-modal-body>
            <div class="search_input">
              <c-input-group>
                <c-input-left-element>
                  <i class="fas fa-search"></i>
                </c-input-left-element>
                <c-input
                  :value="query"
                  @input.native="handleInput"
                  placeholder="Search people..."
                  borderWidth="0"
                  borderRadius="20"
                  size="lg"
                  variant="filled"
                  type="text"
                />
              </c-input-group>
            </div>
            <c-box v-for="attendee in getAttendeesMatching" :key="attendee.id">
              <tagged-person-card :person="attendee.attendee" />
            </c-box>
          </c-modal-body>
        </c-box>
      </c-modal-content>
    </c-modal>
  </c-flex>
</template>

<script>
import { mapState } from 'vuex';
import {
  addPostComment,
  addPostLike,
  deletePostLike,
  getMatchingTags,
  getMatchingUsers,
  updatePostComment,
} from '@/services/insight';
import { generateID } from '@/helpers/data';
import cloneDeep from 'lodash.clonedeep';
import Comment from '@/views/App/Explore/components/Comment.vue';
// import EventDetailSidebar from '@/views/App/Events/components/EventDetailSidebar.vue';
import {
  attendEvent,
  getEventAttendees,
  getEventAttendeesAggregate,
  getEventById,
  getEventsAttendedByUser,
  leaveEvent,
} from '@/services/event';
import { Quill } from 'vue-quill-editor';
import 'quill-mention/dist/quill.mention.min.css';
import 'quill-mention';
import ShareContent from '@/views/App/components/ShareContent.vue';
import { getUrlMetadata } from '@/services/common';
import moment from 'moment';
import TaggedPersonCard from '@/views/App/Explore/components/tagged/TaggedPersonCard.vue';
import SpeakerCard from '@/views/App/Events/components/SpeakerCard.vue';
import Sidebar from '@/views/App/Explore/Sidebar.vue';
import store from '@/store';
import {EventBus} from "@/eventBus";
// import debounce from "lodash.debounce";

export default {
  name: 'SingleEvent',
  components: {
    ShareContent,
    Comment,
    TaggedPersonCard,
    SpeakerCard,
    Sidebar,
  },
  computed: {
    isCreatorOfEvent(){
      return this.event.creator.id === this.user.id;
    },
    isPastEvent(){
      return moment(this.event.end_date).isBefore(moment())
    },
    getAttendeesMatching() {
      return this.attendees.filter((attn) => {
        return (
          attn.attendee.firstname.toLowerCase().includes(this.query) ||
          attn.attendee.lastname.toLowerCase().includes(this.query)
        );
      });
    },
    attendingEvent: {
      get() {
        return this.attendees.find((att) => att.attendee.id === this.user.id);
      },
      set(attendingEventNew) {
        return attendingEventNew;
      },
    },
    eventId: {
      get(){
        return this.$route.params.id;
      },
      set(value){
        return value
      }
    },
    truncatedAttendees() {
      if (this.attendees.length > 5) {
        return this.attendees.slice(
          this.attendees.length - 5,
          this.attendees.length
        );
      } else {
        return this.attendees;
      }
    },
    ...mapState('auth', {
      user: (state) => state.user,
    }),
    isLikedPost() {
      return this.postLikes.some(
        (postLike) => postLike.user.id == this.user.id
      );
    },
    isMyPost() {
      return this.user.id === this.post.userId;
    },
    editor() {
      return this.$refs.textEditor.quill;
    },
    isStaging() {
      return (
        window.location.hostname.startsWith('develop') ||
        window.location.hostname.startsWith('localhost')
      );
    },
  },
  watch: {
    '$route.params.id'(newId, oldId) {
      this.eventId = newId
      if(newId !== oldId){
        // Logic to handle the change of the event ID
        this.getEvent(); // or whatever function loads your event details
      }
    }
  },
  data() {
    return {
      hideSidebar: false,
      eventAttending: [],
      query: '',
      isAttendeesModalOpen: false,
      deleteComment: false,
      isAllCommentsShown: false,
      isShareOpen: false,
      isCommentBoxVisible: false,
      isSavingComment: false,
      isEditMode: false,
      editingComment: null,
      isDeleteDialogOpen: false,
      commentToDelete: null,
      isDeletingComment: false,
      postInAction: {},
      editorOptions: {
        placeholder: `Comment`,
        modules: {
          toolbar: false,
          clipboard: {
            matchers: [[Node.TEXT_NODE, this.linkMatcher]],
          },
          mention: {
            maxChars: 31,
            isolateCharacter: true,
            allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
            mentionDenotationChars: ['@', '#'],
            blotName: 'vc-mention',
            source: async (searchTerm, renderList, mentionChar) => {
              try {
                let matches = [];
                if (mentionChar === '@') {
                  matches = await this.getUserMatches(searchTerm);
                } else {
                  matches = await this.getTagMatches(searchTerm);
                }
                renderList(matches, searchTerm);
              } catch (e) {
                console.log({ e });
                renderList([], searchTerm);
              }
            },
          },
        },
      },
      urls: [],
      event: null,
      postLikes: [],
      postComments: [],
      newComment: null,
      isFetchingEvent: false,
      attendees: [],
      isAttending: false,
      attendantsNo: 0,
    };
  },
  created() {
    this.getEvent();
    this.getEventAttendees();
    this.getEventAttendeesAggregate();
    this.getEventsAttended();
  },
  methods: {
    openEditDialog(){
      EventBus.$emit('open-event-edit-modal', this.event)
    },
    getEventsAttended() {
      this.isFetchingEvents = true;
      const loggedInUserId = store.state.auth.user.id;
      getEventsAttendedByUser(loggedInUserId).then((res) => {
        this.eventAttending = res.data.event;
        // this.eventsTotal = res.data.event_aggregate.aggregate.count;
        this.isFetchingEvents = false;
      });
    },
    simplifyDate(_date) {
      const dayOfWeek = _date.toLocaleString('default', { weekday: 'short' });
      const day = _date.getDate();
      const month = _date.toLocaleString('default', { month: 'short' });
      const year = _date.getFullYear();
      let hours = _date.getHours();
      const minutes = _date.getMinutes().toString().padStart(2, '0');
      const ampm = hours >= 12 ? 'PM' : 'AM';
      hours = hours % 12;
      hours = hours ? hours : 12; // the hour '0' should be '12'

      return `${dayOfWeek}, ${month} ${day}, ${year}, ${hours}:${minutes} ${ampm}`;
    },

    closeAttendeesModal() {
      this.isAttendeesModalOpen = false;
    },
    // search: debounce(async function (searchQuery) {
    //   if (!searchQuery) {
    //     await this.getEventAttendees();
    //     return;
    //   }
    //
    //   // Replace with your actual search logic/API call
    //   await this.getAttendeesMatching();
    // }, 400), // 3
    formatEventTime(dateString) {
      return moment(dateString, 'MMMM Do YYYY, h:mm:ss a');
    },

    getUrls() {
      const urls = [
        ...new Set(
          this.event.posts[0].content.match(
            /[(http(s)?)://(www.)?a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/gi
          )
        ),
      ];
      if (!urls.length) return;
      urls.forEach(async (url) => {
        try {
          const res = await getUrlMetadata(url);
          this.urls.push({
            id: generateID(4),
            ...res.data,
          });
        } catch (e) {
          // do something
        }
      });
    },
    getTextBeforeCursor(cursorPosition) {
      const startPos = Math.max(
        0,
        cursorPosition - this.editorOptions.modules.mention.maxChars
      );
      return this.editor.getText(startPos, cursorPosition - startPos);
    },
    closeDeleteDialog() {
      this.isDeleteDialogOpen = false;
      this.commentToDelete = null;
    },
    onDeleteComment() {},
    onDeleteClick() {
      this.$emit('deletePost');
    },
    onEditComment(comment) {
      this.editingComment = comment;
      this.isEditMode = true;
      this.newComment = comment.content;
      this.$nextTick(() => {
        this.editor.setSelection(this.editor.getLength(), 0);
      });
    },
    hasValidMentionCharIndex(mentionCharIndex, text) {
      if (mentionCharIndex > -1) {
        if (
          !(mentionCharIndex === 0 || !!text[mentionCharIndex - 1].match(/\s/g))
        ) {
          return false;
        }
        return true;
      }
      return false;
    },
    async getUserMatches(searchTerm) {
      const res = await getMatchingUsers(`%${searchTerm}%`);
      const matches = res.data.user.map((user) => {
        return {
          id: user.id,
          value: `${user.firstname} ${user.lastname}`,
        };
      });
      return matches;
    },
    async getTagMatches(searchTerm) {
      const res = await getMatchingTags(`%${searchTerm}%`);
      const matches = res.data.tag.map((tag) => {
        return {
          id: tag.id,
          value: tag.name,
        };
      });
      return matches;
    },
    getCommentMentions() {
      const mentions = Array.from(
        this.$refs.textEditor.$el.querySelectorAll(
          '.mention[data-denotation-char="@"]'
        )
      ).map((el) => el.dataset);
      return mentions.map((mention) => {
        return {
          user: mention,
        };
      });
    },
    getEventAttendeesAggregate() {
      getEventAttendeesAggregate({ eventId: this.eventId }).then((res) => {
        console.log(res);
        this.attendantsNo = res.data.event_attendee_aggregate.aggregate.count;
      });
    },
    getEventAttendees() {
      getEventAttendees({ eventId: this.eventId, limit: 5 })
        .then((res) => {
          this.attendees = res.data.event_attendee;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    leave() {
      this.attendingEvent = true;
      try {
        leaveEvent({ eventId: this.eventId, attendeeId: this.user.id });
        this.$toast({
          title: 'You have successfully left this event',
          description: `Successfully left an event`,
          status: 'success',
          position: 'top',
          duration: 3000,
        });
        this.isAttending = false;
        this.attendees.pop();
      } catch (e) {
        this.$toast({
          title: 'An error occurred.',
          description: `Could leave event, please try again`,
          status: 'error',
          position: 'top',
          duration: 3000,
        });
      }
    },
    attend() {
      this.isAttending = true;
      try {
        attendEvent({ eventId: this.event.id });
        this.$toast({
          title: 'You have successfully Registered',
          description: `Successfully registered and signed up for event`,
          status: 'success',
          position: 'top',
          duration: 3000,
        });
        this.isAttending = false;
        this.attendees.push({
          attendee: this.user,
        });
      } catch (e) {
        this.$toast({
          title: 'An error occurred.',
          description: `Could not attend event, please try again`,
          status: 'error',
          position: 'top',
          duration: 3000,
        });
      }
    },
    async getEvent() {
      this.isFetchingEvent = true;
      await getEventById({
        id: this.eventId,
      })
        .then((res) => {
          this.event = res.data.event_by_pk;

          // this.getUrls();
          this.postLikes = cloneDeep(this.event.posts[0].post_likes);
          console.log('Lokes');
          console.log(this.postLikes);
          if(this.event.posts.length){
            this.postComments = cloneDeep(this.event.posts[0].post_comments);
          }
          this.isFetchingEvent = false;
        })
        .catch((e) => {
          console.log('Error', e);
        });
    },
    onShareClick(post = {}) {
      this.isShareOpen = !this.isShareOpen;
      this.postInAction = post;
    },
    async togglePostLike() {
      if (this.isLikedPost) {
        const postLike = this.postLikes.find(
          (like) => like.userId === this.user.id
        );
        const postLikeIndex = this.postLikes.find(
          (like) => like.userId === this.user.id
        );
        try {
          this.postLikes.splice(postLikeIndex, 1);
          await deletePostLike(postLike.id);
        } catch (e) {
          this.postLikes.splice(postLikeIndex, 0, postLike);
          this.$toast({
            title: 'An error occurred.',
            description: `Could not unlike post, please try again`,
            status: 'error',
            position: 'top',
            duration: 3000,
          });
        }
      } else {
        const likeId = generateID(4);
        try {
          this.postLikes.push({
            id: likeId,
            user: { id: this.user.id },
          });
          const res = await addPostLike({
            postId: this.event.posts[0].id,
          });
          const postLikeIndex = this.postLikes.findIndex(
            (like) => like.id === likeId
          );
          this.postLikes[postLikeIndex] = cloneDeep(
            res.data.insert_post_like_one
          );
        } catch (e) {
          const postLikeIndex = this.postLikes.findIndex(
            (like) => like.id === likeId
          );
          this.postLikes.splice(postLikeIndex, 1);
          this.$toast({
            title: 'An error occurred.',
            description: `Could not like post, please try again`,
            status: 'error',
            position: 'top',
            duration: 3000,
          });
        }
      }
    },
    handleInput(event) {
      if (event.target) {
        this.query = event.target.value;
        // this.search(this.query);
      }
    },
    handleCommentKeyUp(e) {
      if (e.key === 'Enter' || e.code === 'Enter') {
        this.addComment();
      }
    },
    submitComment() {
      if (this.isEditMode) {
        this.updateComment();
      } else {
        this.addComment();
      }
    },
    async addComment() {
      const {
        id: userId,
        email,
        profilePhoto,
        firstname,
        lastname,
      } = this.user;
      const data = {
        content: this.newComment,
        postId: this.event.posts[0].id,
      };
      const commentId = generateID(4);
      this.postComments.push({
        id: commentId,
        postId: this.event.posts[0].id ?? null,
        user: { id: userId, email, profilePhoto, firstname, lastname },
        content: this.newComment,
        replies: [],
        createdAt: new Date().toISOString(),
      });
      this.newComment = null;
      const commentIndex = this.postComments.findIndex(
        (comment) => comment.id == commentId
      );
      try {
        const res = await addPostComment(data);
        this.postComments[commentIndex] = cloneDeep(
          res.data.insert_post_comment_one
        );
      } catch (e) {
        this.postComments.splice(commentIndex, 1);
        this.$toast({
          title: 'An error occurred.',
          description: `Could not comment on post, please try again`,
          status: 'error',
          position: 'top',
          duration: 3000,
        });
      }
    },
    setContentEmpty() {
      this.isContentEmpty = !this.editor.getText().trim();
    },
    onChange() {
      const range = this.editor.getSelection();
      if (range == null) return;
      const cursorPosition = range.index;
      const textBeforeCursor = this.getTextBeforeCursor(cursorPosition);
      const indexOfHash = textBeforeCursor.lastIndexOf('#');
      const mentionCharPos =
        cursorPosition - (textBeforeCursor.length - indexOfHash);
      if (indexOfHash > -1) {
        this.renderHashTag({
          textBeforeCursor,
          indexOfHash,
          cursorPosition,
          mentionCharPos,
        });
      }
      this.setContentEmpty();
    },
    renderHashTag({
      textBeforeCursor,
      indexOfHash,
      cursorPosition,
      mentionCharPos,
    }) {
      if (!this.hasValidMentionCharIndex(indexOfHash, textBeforeCursor)) {
        return;
      }
      const textAfter = textBeforeCursor.substring(indexOfHash + 1);
      if (/\s$/.test(textBeforeCursor) && textAfter.trim()) {
        //   this.editor.getFormat()
        this.editor.deleteText(
          mentionCharPos,
          cursorPosition - mentionCharPos,
          Quill.sources.USER
        );
        this.editor.insertEmbed(
          mentionCharPos,
          this.editorOptions.modules.mention.blotName,
          { value: textAfter.trim(), denotationChar: '#' },
          Quill.sources.USER
        );
        this.editor.insertText(mentionCharPos + 1, ' ', Quill.sources.USER);
        this.editor.setSelection(mentionCharPos + 2, Quill.sources.USER);
      }
    },
  },
  linkMatcher(node, delta) {
    const regex = /https?:\/\/[^\s]+/g;
    if (typeof node.data !== 'string') return;
    const matches = node.data.match(regex);

    if (matches && matches.length > 0) {
      const ops = [];
      let str = node.data;
      matches.forEach(function (match) {
        const split = str.split(match);
        const beforeLink = split.shift();
        ops.push({ insert: beforeLink });
        ops.push({ insert: match, attributes: { link: match } });
        str = split.join(match);
      });
      ops.push({ insert: str });
      delta.ops = ops;
    }

    return delta;
  },
  getCommentMentions() {
    const mentions = Array.from(
      this.$refs.textEditor.$el.querySelectorAll(
        '.mention[data-denotation-char="@"]'
      )
    ).map((el) => el.dataset);
    return mentions.map((mention) => {
      return {
        user: mention,
      };
    });
  },
  onDeleteComment(comment) {
    this.isDeleteDialogOpen = true;
    this.commentToDelete = { ...comment };
  },
  async deleteComment() {
    this.isDeletingComment = true;
    try {
      await updatePostComment({
        id: this.commentToDelete.id,
        set: { isDeleted: true },
      });
      const commentIndex = this.postComments.findIndex(
        (comment) => comment.id === this.commentToDelete.id
      );
      this.postComments.splice(commentIndex, 1);
      this.closeDeleteDialog();
      this.$toast({
        title: 'Success!!!',
        description: `Comment has been deleted`,
        status: 'success',
        position: 'top',
        duration: 3000,
      });
      this.isDeletingComment = false;
    } catch (e) {
      this.isDeletingComment = false;
      this.$toast({
        title: 'An error occurred.',
        description: `Could not delete post, please try again.`,
        status: 'error',
        position: 'top',
        duration: 3000,
      });
    }
  },
  onSaveClick() {
    this.$emit('togglePostSave', { ...this.post });
  },
  onDeleteClick() {
    this.$emit('deletePost');
  },
  onEditComment(comment) {
    this.editingComment = comment;
    this.isEditMode = true;
    this.newComment = comment.content;
    this.$nextTick(() => {
      this.editor.setSelection(this.editor.getLength(), 0);
    });
  },
};
</script>

<style lang="scss" scoped>
.link_active {
  color: orangered;
  text-decoration: underline;
}
.link_disabled {
  color: gray;
  text-decoration: underline;
}
.main_sidebar-e {
  max-width: 350px;

  &__section {
    padding: 15px 20px;
    border: 1px solid #dae7ec;
    box-shadow: 0 3px 6px rgba(186, 186, 186, 0.16);
    border-radius: 8px;
    background-color: white;

    .see_more {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 10px;
      cursor: pointer;
      background-color: #e2e8f4;
      font-weight: 600;
      font-size: 16px;
      padding: 10px;
    }
  }
}
.modal-contents {
  max-height: 600px;
  overflow: hidden;
  overflow-y: scroll;
}

.grid-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr; /* Three equal columns */
  grid-gap: 10px; /* Gap between grid items */
}

.item {
  background-color: #4267b222;
  border-radius: 5px;
  padding: 20px;
  text-align: center;
}

.editor {
  @apply max-w-full;
  ::v-deep {
    .ql-container {
      border-width: 1px;
      font-size: inherit;
      font-family: inherit;
      height: 60px;
    }
    .mention {
      background-color: transparent;
      @apply text-blue-500 font-semibold;
    }
    .ql-mention-list {
      @apply overflow-y-scroll;
      max-height: 300px;
    }
  }
}
</style>
